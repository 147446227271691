// Generated by Framer (937e5b9)

import { addFonts, cx, CycleVariantState, SVG, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["rbwHmfEfx"];

const variantClassNames = {rbwHmfEfx: "framer-v-1kvkjet"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "rbwHmfEfx", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-4Ffc1", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-1kvkjet", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"rbwHmfEfx"} ref={ref} style={{...style}}><SVG className={"framer-1nf68nd"} data-framer-name={"graphic"} layout={"position"} layoutDependency={layoutDependency} layoutId={"HF1jhtPEi"} opacity={1} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 20 20\"><path d=\"M 4.8 0.24 C 4.935 0.386 5.007 0.58 5 0.778 C 4.992 0.977 4.906 1.165 4.76 1.3 L 1.852 4 L 4.76 6.7 C 5.064 6.982 5.082 7.456 4.8 7.76 C 4.518 8.064 4.044 8.082 3.74 7.8 L 0.24 4.55 C 0.087 4.408 0 4.209 0 4 C 0 3.791 0.087 3.592 0.24 3.45 L 3.74 0.2 C 3.886 0.065 4.08 -0.007 4.278 0.001 C 4.477 0.008 4.665 0.094 4.8 0.24 Z\" transform=\"translate(7 6) rotate(180 2.5 4)\" fill=\"var(--token-2a680c9a-4e39-4faf-a979-408e1697909f, rgb(29, 30, 32)) /* {&quot;name&quot;:&quot;Content Primary&quot;} */\"></path></svg>"} svgContentId={465610980} withExternalLayout/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-4Ffc1 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-4Ffc1 .framer-1hlkjht { display: block; }", ".framer-4Ffc1 .framer-1kvkjet { height: 20px; overflow: hidden; position: relative; width: 20px; }", ".framer-4Ffc1 .framer-1nf68nd { flex: none; height: 20px; left: calc(50.00000000000002% - 20px / 2); position: absolute; top: calc(50.00000000000002% - 20px / 2); width: 20px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 20
 * @framerIntrinsicWidth 20
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 */
const FramerWK_5vEr9T: React.ComponentType<Props> = withCSS(Component, css, "framer-4Ffc1") as typeof Component;
export default FramerWK_5vEr9T;

FramerWK_5vEr9T.displayName = "icon";

FramerWK_5vEr9T.defaultProps = {height: 20, width: 20};

addFonts(FramerWK_5vEr9T, [])